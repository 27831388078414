import React from 'react';
import {
  AppBar, Toolbar, Typography, Button, Box, IconButton, useTheme, useMediaQuery, Drawer, List, ListItem, ListItemText
} from '@mui/material';
import { Link } from 'react-router-dom';
import DarkModeToggle from '../components/DarkModeToggle';
import { CloudDownload } from '@mui/icons-material';
import { styled } from '@mui/system';

const Header = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleDownloadPDF = () => {
    // The path to the PDF file in the public folder
    const pdfPath = '/resume.pdf';

    // Create a temporary link element to trigger the download
    const link = document.createElement('a');
    link.href = pdfPath;
    link.setAttribute('download', 'resume.pdf'); // Name the file to be downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  // Styled component for circular logo
  const CircularLogo = styled('div')({
    width: '40px', // Adjusted width
    height: '40px', // Adjusted height
    borderRadius: '50%', // Make it circular
    backgroundColor: '#000000', // Black background color
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden', // Ensure the image stays within the circle
  });

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <CircularLogo>
            <img src="/logo.svg" alt="Logo" style={{ width: '30px' }} />
          </CircularLogo>
          <Typography variant="h6" component={Link} to="/" sx={{ textDecoration: 'none', color: 'inherit', ml: 1 }}>
            KOKOSHARI
          </Typography>
        </Box>
        {isSmallScreen ? (
          <>
            <DarkModeToggle />
            <IconButton onClick={handleDownloadPDF} color="inherit">
              <CloudDownload />
            </IconButton>
          </>
        ) : (
          <Box>
            <DarkModeToggle />
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadPDF}
              startIcon={<CloudDownload />}
              sx={{ borderRadius: '20px', ml: 2 }}
            >
              Download CV
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
