import React from 'react';
import { Paper, Typography, Box, Stack } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Experience = () => {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        mb: 3,
        bgcolor: 'background.default',
        color: 'text.primary',
        borderRadius: 2,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          boxShadow: 6,
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <WorkIcon sx={{ fontSize: 40, mr: 2, color: 'primary.main' }} />
        <Typography variant="h6">Work Experience</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <ExperienceItem
          title="Full Stack Developer"
          company="Reply"
          date="05/2023 - Present"
          location="Padova, Italy"
          description={`
            <p><strong>Overview:</strong> As a Full Stack Developer at Reply, I oversee a wide array of client projects leveraging React, Java Spring Boot, and Rust. I have played a key role in spearheading the development of robust solutions tailored for clients such as Moncler, Stevanato, and Despair.
            <ul>
              <li><strong>RDM Project:</strong> Enhanced Moncler's UI with React and Java Spring Boot, improving user interaction.</li>
              <li><strong>Inspair Project:</strong> Optimized backend solutions in Rust for Stevanato, enhancing performance and scalability.</li>
              <li><strong>Afc, iban-check, tidtracker Projects:</strong> Developed React and Spring Boot solutions for Despair, boosting backend efficiency and user engagement.</li>
            </ul>
            <p>Implemented agile methodologies like Scrum, ensuring timely delivery of high-quality solutions and seamless deployment across projects.</p>
          `}
        />
        <ExperienceItem
          title="Salesforce Developer"
          company="H.R.M Group"
          date="05/2022 – 05/2023"
          location="Milano, Italy"
          description={`
            <p><strong>Overview:</strong> As a Salesforce Developer at H.R.M Group, I specialized in e-commerce projects using Salesforce Commerce Cloud:</p>
            <ul>
              <li><strong>Commerce Cloud:</strong> Developed cutting-edge e-commerce websites, applying Salesforce Commerce Cloud for enhanced user experience.</li>
              <li><strong>Collaborative Approach:</strong> Worked closely with teams to deliver scalable solutions meeting client needs and industry standards.</li>
              <li><strong>Continuous Learning:</strong> Stayed updated with Salesforce advancements, ensuring optimal platform utilization in project delivery.</li>
            </ul>
            <p>Contributed significantly to H.R.M Group's projects, leveraging Salesforce Commerce Cloud to drive impactful outcomes for clients.</p>
          `}
        />
        <ExperienceItem
          title="Full Stack Developer"
          company="Pragma Management Systems"
          date="12/2021 – 12/2022"
          location="Vicenza, Italy"
          description={`
            <p><strong>Overview:</strong> At Pragma Management Systems, I developed logistics solutions for DHL and dynamic websites for Versal:</p>
            <ul>
              <li><strong>DHL Project:</strong> Engineered a Spring Boot application, optimizing parcel tracking at the Malpensa warehouse.</li>
              <li><strong>Versal Project:</strong> Designed dynamic websites with robust document management, enhancing data organization.</li>
              <li><strong>Technology Proficiency:</strong> Mastered JavaScript, React, jQuery, Spring Boot, MariaDB, and IBM Application Connect Enterprise.</li>
            </ul>
            <p>Collaborated in cross-functional teams to deliver innovative software solutions exceeding client expectations.</p>
          `}
        />
        <ExperienceItem
          title="Full Stack Developer"
          company="GFT Technologies"
          date="02/2021 – 06/2021"
          location="Padova, Italy"
          description={`
            <p><strong>Overview:</strong> Contributed to a comprehensive banking portal web application, utilizing Java, ICEfaces, Vue.js, and Spring framework:</p>
            <ul>
              <li><strong>Banking Portal:</strong> Developed robust solutions for complex banking functionalities.</li>
              <li><strong>Responsive Design:</strong> Ensured seamless user experiences across desktop and mobile platforms.</li>
              <li><strong>Quality Assurance:</strong> Conducted rigorous testing and debugging to uphold software quality and security.</li>
            </ul>
            <p>Expanded expertise in full-stack development and delivered solutions in a dynamic corporate environment at GFT Technologies.</p>
          `}
        />
        <ExperienceItem
          title="Full Stack Developer Intern"
          company="RiskAPP"
          date="10/2020 – 12/2020"
          location="Conselve, Italy"
          description={`
            <p><strong>Overview:</strong> Internship at RiskAPP during university studies at the University of Padua:</p>
            <ul>
              <li>Developed a prototype software for video calls using WebRTC, enhancing user experience.</li>
              <li>Collaborated on feature design and implementation, incorporating user feedback for seamless communication.</li>
            </ul>
            <p>Strengthened skills in full-stack development and user-centric design through practical software development.</p>
          `}
        />
        <ExperienceItem
          title="Accountant Intern"
          company="M.D.M"
          date="06/2014 – 09/2014"
          location="Conselve, Italy"
          description={`
            <p><strong>Overview:</strong> Internship during high school at Cattaneo - Mattei:</p>
            <ul>
              <li>Created documentation for product-related materials and economic balance sheets.</li>
              <li>Gained practical experience in financial documentation and analysis.</li>
            </ul>
            <p>Enhanced understanding of economic principles within a corporate environment at M.D.M.</p>
          `}
        />
      </Box>
    </Paper>
  );
};

const ExperienceItem = ({ title, company, date, location, description }) => (
  <Paper
    elevation={3}
    sx={{
      p: 2,
      bgcolor: 'background.paper',
      borderRadius: 2,
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        transform: 'translateY(-5px)',
      },
    }}
  >
    <Typography variant="subtitle1">{title}</Typography>
    <Typography variant="subtitle2" gutterBottom>
      {company}
    </Typography>
    <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
      <LocationOnIcon sx={{ fontSize: '1.2rem', color: 'primary.main' }} />
      <Typography variant="body2">{location}</Typography>
    </Stack>
    <Typography variant="body2" paragraph>
      {date}
    </Typography>
    <Box sx={{ typography: 'body2', '& ul': { pl: 3, mb: 1 } }} dangerouslySetInnerHTML={{ __html: description }} />
  </Paper>
);

export default Experience;
