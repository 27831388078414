import React from 'react';
import { Paper, Typography, Box, Link } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Education = () => {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        mb: 3,
        bgcolor: 'background.default',
        color: 'text.primary',
        borderRadius: 2,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          boxShadow: 6,
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <SchoolIcon sx={{ fontSize: 40, mr: 2, color: 'primary.main' }} />
        <Typography variant="h6">Education and Training</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <EducationItem
          degree="Bachelor of Science in Computer Science"
          institution="University of Studies of Padua"
          date="05/10/2015 – 16/12/2022"
          description="Provides advanced education in computer science with a focus on practical applications and theoretical knowledge."
          link="https://informatica.math.unipd.it/"
          location="Padova, Italy"
        />
        <EducationItem
          degree="Commercial Services"
          institution="I.I.S. Cattaneo - Mattei"
          date="13/09/2010 – 12/06/2015"
          description="Focuses on preparing students with skills in commercial operations, customer service, and business administration."
          link="https://www.cattaneo-mattei.edu.it"
          location="Conselve, Italy"
        />
      </Box>
    </Paper>
  );
};

const EducationItem = ({ degree, institution, date, description, link, location }) => (
  <Paper
    elevation={3}
    sx={{
      p: 2,
      bgcolor: 'background.paper',
      borderRadius: 2,
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        transform: 'translateY(-5px)',
      },
    }}
  >
    <Typography variant="subtitle1">{degree}</Typography>
    <Typography variant="subtitle2" gutterBottom>
      {institution}
    </Typography>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <LocationOnIcon sx={{ fontSize: '1.2rem', color: 'primary.main', mr: 1 }} />
      <Typography variant="body2">{location}</Typography>
    </Box>
    <Typography variant="body1" paragraph>
      {description}
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <Link
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {link}
          <OpenInNewIcon sx={{ fontSize: '0.8em', verticalAlign: 'middle', ml: 0.5 }} />
        </Link>
      </Box>
    </Typography>
    <Typography variant="body2" color="text.secondary">
      {date}
    </Typography>
  </Paper>
);

export default Education;
