import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';

const Languages = () => {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        mb: 3,
        bgcolor: 'background.default',
        color: 'text.primary',
        borderRadius: 2,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          boxShadow: 6,
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <LanguageIcon sx={{ fontSize: 40, mr: 2, color: 'primary.main' }} />
        <Typography variant="h6">Languages</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <LanguageItem name="Italian" proficiency="Proficient" />
        <LanguageItem name="English" proficiency="Intermediate" />
        <LanguageItem name="French" proficiency="Basic" />
      </Box>
    </Paper>
  );
};

const LanguageItem = ({ name, proficiency }) => (
  <Paper
    elevation={3}
    sx={{
      p: 2,
      bgcolor: 'background.paper',
      borderRadius: 2,
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        transform: 'translateY(-5px)', // Example of animation on hover
      },
    }}
  >
    <Typography variant="subtitle1">{name}</Typography>
    <Typography variant="body1" color="text.secondary">
      Proficiency: {proficiency}
    </Typography>
  </Paper>
);

export default Languages;
