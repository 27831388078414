import React from 'react';
import { Box, Typography, Paper, Avatar, Stack, useTheme } from '@mui/material';
import { LocationOn, Email, Phone, Language } from '@mui/icons-material';

const Profile = () => {
  const theme = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        mb: 3,
        bgcolor: theme.palette.background.paper, // Use a lighter background for paper
        color: theme.palette.text.primary,
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        gap: 3,
        borderRadius: 8, // Increase border radius for a softer look
        transition: 'box-shadow 0.3s ease-in-out', // Smooth transition for boxShadow
        '&:hover': {
          boxShadow: `0px 4px 20px rgba(0, 0, 0, 0.1)`, // Enhanced boxShadow on hover
        },
      }}
    >
      <Avatar
        alt="Gerardo Kokoshari"
        src="/avatar.jpg" // Replace with a real image path or URL
        sx={{
          width: { xs: 120, sm: 140 }, // Adjust width for different screen sizes
          height: { xs: 120, sm: 140 }, // Adjust height for different screen sizes
          borderRadius: '50%',
          transition: 'transform 0.3s ease-in-out', // Smooth transition for animation
          '&:hover': {
            transform: 'scale(1.05)', // Scale up on hover slightly
          },
        }}
      />
      <Box sx={{ flexGrow: 1, textAlign: { xs: 'center', sm: 'left' } }}>
        <Typography variant="h5" gutterBottom>
          Gerardo Kokoshari
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        <b> Role:</b>  Full Stack Developer
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
        <b> Age:</b>  28
        </Typography>
        <Typography variant="body1" paragraph>
          <b> Summary:</b> Enthusiastic and detail-oriented Full Stack Developer with over 3 years of experience in designing and developing scalable web applications. Proficient in modern web technologies including Java, Rust, C++, JavaScript, React, Node.js, and more.
        </Typography>
        <Stack direction="row" sx={{ mt: 2, flexWrap: 'wrap', gap: 3 }}>
          <InfoItem icon={<LocationOn sx={{ color: theme.palette.primary.main, fontSize: '1.2rem', marginRight: '0.5rem' }} />} text="Venice, IT" />
          <InfoItem icon={<Email sx={{ color: theme.palette.primary.main, fontSize: '1.2rem', marginRight: '0.5rem' }} />} text="kokoshari@gmail.com" link="mailto:kokoshari@gmail.com" />
          <InfoItem icon={<Phone sx={{ color: theme.palette.primary.main, fontSize: '1.2rem', marginRight: '0.5rem' }} />} text="+39 3291639884" />
          <InfoItem icon={<Language sx={{ color: theme.palette.primary.main, fontSize: '1.2rem', marginRight: '0.5rem' }} />} text="www.kokoshari.com" link="https://www.kokoshari.com" />
        </Stack>
      </Box>
    </Paper>
  );
};

const InfoItem = ({ icon, text, link }) => (
  <Box
    component={link ? 'a' : 'div'}
    href={link}
    target={link ? '_blank' : undefined}
    rel={link ? 'noopener noreferrer' : undefined}
    sx={{
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: 'inherit',
      transition: 'color 0.3s ease-in-out',
      '&:hover': {
        color: 'primary.main',
      },
    }}
  >
    {icon}
    <Typography variant="body2">{text}</Typography>
  </Box>
);

export default Profile;
