import React, { useContext } from 'react';
import { IconButton } from '@mui/material';
import { DarkMode, LightMode } from '@mui/icons-material'; 
import { ThemeContext } from '../context/ThemeContext';

const DarkModeToggle = () => {
    const { themeMode, toggleTheme } = useContext(ThemeContext);
  
    console.log("Theme Mode:", themeMode);
    console.log("Toggle Theme Function:", toggleTheme);
  
    if (!themeMode || !toggleTheme) {
      console.error("ThemeContext is not provided correctly");
    }
  
    return (
      <IconButton onClick={toggleTheme} color="inherit">
        {themeMode === 'light' ? <DarkMode /> : <LightMode />}
      </IconButton>
    );
  };
  
export default DarkModeToggle;
