import React from 'react';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
import { LinkedIn, GitHub, Twitter, Instagram } from '@mui/icons-material';

const Footer = () => {
  const theme = useTheme(); // Access the current theme

  return (
    <Box
      component="footer"
      sx={{
        textAlign: 'center',
        p: 2,
        bgcolor: theme.palette.background.paper, // Dynamic background color
      }}
    >
      <Box
        sx={{
          mt: 3,
          display: 'flex',
          justifyContent: 'center', // Center on xs (small screens), start on sm (medium screens)
          gap: 2,
          width: '100%', // Ensure full width on small screens
        }}
      >
        <IconButton
          component="a"
          href="https://linkedin.com/in/gerardo-kokoshari/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn"
          sx={{
            color: theme.palette.primary.main,
            transition: 'color 0.3s ease-in-out',
            '&:hover': { color: theme.palette.secondary.main },
          }}
        >
          <LinkedIn />
        </IconButton>
        <IconButton
          component="a"
          href="https://github.com/kira001"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="GitHub"
          sx={{
            color: theme.palette.primary.main,
            transition: 'color 0.3s ease-in-out',
            '&:hover': { color: theme.palette.secondary.main },
          }}
        >
          <GitHub />
        </IconButton>
        <IconButton
          component="a"
          href="https://twitter.com/gery960"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter"
          sx={{
            color: theme.palette.primary.main,
            transition: 'color 0.3s ease-in-out',
            '&:hover': { color: theme.palette.secondary.main },
          }}
        >
          <Twitter />
        </IconButton>
        <IconButton
          component="a"
          href="https://instagram.com/kokoshman"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
          sx={{
            color: theme.palette.primary.main,
            transition: 'color 0.3s ease-in-out',
            '&:hover': { color: theme.palette.secondary.main },
          }}
        >
          <Instagram />
        </IconButton>
      </Box>
      <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
        © 2024 Kokoshari. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
