import React from 'react';
import Profile from '../components/Profile';
import Experience from '../components/Experience';
import Education from '../components/Education';
import Languages from '../components/Languages';
import Skills from '../components/Skills';
import Page from '../layout/Page';

const Resume = () => {
  return (
    <Page id="resume-content">
      <Profile />
      <Experience />
      <Education />
      <Skills />
      <Languages />
    </Page>
  );
};

export default Resume;
