import React from 'react';
import { Paper, Typography, LinearProgress, Box } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';

const Skills = () => {
  const skills = [
    { name: 'Java', level: 80 },
    { name: 'C++', level: 70 },
    { name: 'Rust', level: 70 },
    { name: 'JavaScript', level: 70 },
    { name: 'React', level: 85 },
    { name: 'Node.js', level: 80 },
    { name: 'HTML', level: 95 },
    { name: 'CSS', level: 85 },
    { name: 'SQL', level: 75 },
    { name: 'Python', level: 30 },
  ];

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        mb: 3,
        bgcolor: 'background.default',
        color: 'text.primary',
        borderRadius: 2,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          boxShadow: 6,
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <CodeIcon sx={{ fontSize: 40, mr: 2, color: 'primary.main' }} />
        <Typography variant="h6">Skills</Typography>
      </Box>
      {skills.map((skill, index) => (
        <SkillItem key={index} name={skill.name} level={skill.level} />
      ))}
    </Paper>
  );
};

const SkillItem = ({ name, level }) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="subtitle1" sx={{ mb: 1 }}>{name}</Typography>
    <LinearProgress
      variant="determinate"
      value={level}
      sx={{
        height: 10,
        borderRadius: 5,
        '& .MuiLinearProgress-bar': {
          borderRadius: 5,
          backgroundColor: 'primary.main',
          transition: 'width 1s ease-in-out', // Animation for progress bar width
        },
      }}
    />
  </Box>
);

export default Skills;
